
<template>
<div>
    <div v-if="!isFromDocument" :class="data && data.validations.vertical_line ? 'vertical-line' : 'horizontal'" :style="getStyle"></div>
    <div v-else-if="isFromDocument" :class="data && data.vertical_line ? 'vertical-lineDocs' : 'horizontalDocs'" :style="getStyle"> </div> 
</div>
</template>


<script>
import { bus } from "../../../main";
export default {

  created(){
    bus.$on("updatingFieldHeight",(data)=>{
      this.lineHeight = data.fieldHeight
    })
  },
  name: "templates-formComponentsExecute-HorizontalLineExecute",
  props: ["data", "isView", "isFromDocument", "allFields","fromEntityViews"],
  data() {
    return {
      lineHeight:"",
    };
  },
  mounted() {
      },
  computed: {
    getHorizontalStyle(){
       return (
        `height:${this.data.height}px;width:${this.data.width}px;overflow:auto;` +
        this.getElementStyle
      );
    },
        getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
      }
      return borderStyle;
    },
     getStyle() {
      if(this.data && this.data.validations && !this.data.validations.vertical_line && !this.isFromDocument){
        return `border-bottom:${
        this.data && this.data.styles && this.data.styles.line_size
          ? this.data.styles.line_size
          : 4
      }px solid ${
        this.data && this.data.styles && this.data.styles.line_color
          ? this.data.styles.line_color
          : "#00000"
      };width:${this.data.width}px;`;
      }if(this.data && this.data.validations && this.data.validations.vertical_line && !this.isFromDocument){
        return `border-left:${
        this.data && this.data.styles && this.data.styles.line_size
          ? this.data.styles.line_size
          : 4
      }px solid ${
        this.data && this.data.styles && this.data.styles.line_color
          ? this.data.styles.line_color
          : "#00000"
      };width:20px;height:${this.data.height}px;`;
      }
      else{
        return this.data && this.data.vertical_line ? `border-left: 2px solid black; height:${this.lineHeight != "" ? this.lineHeight : this.data.height}px;background:none !important;` : `border-bottom:${2}px solid #000000;background:none !important;`
      }
    },
  },
   
};
</script>

<style lang="scss">
.vertical-line:after{
  border-left: 1px solid black;
  background:none !important;
  content: " ";
//  border-left: 1px solid black; /* Change the color and width as desired */
 // height: 100%; /* Change the height as desired */
}
.horizontal:after {
  content: " ";
  border-bottom: 0.1px solid black ;
  background:none !important
}
.horizontalDocs:after {
  content: " ";
  border-bottom: 0.1px solid black ;
  background:none !important;
}
hr{
  border-bottom: 0.1px solid black ;
  background:none !important
  // border-top: 0.0725rem solid black !important;
}
</style>